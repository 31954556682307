@media (max-width: 558px) {

}

@media (max-width: 480px) {
    .list .item__header div.item--placeofbirth, .list .list__header div.item--placeofbirth {
        display: none;
    }

    .list .item__header div.item--birthdate, .list .list__header div.item--birthdate,
    .list .item__header div.item--placeofbirth, .list .list__header div.item--placeofbirth,
    .list .item__header div.item--placeofdeath, .list .list__header div.item--placeofdeath,
    .list .item__header div.item--departure, .list .list__header div.item--departure {
        width: 50%;
    }

    .list .item__media img {
        margin-left: 10px;
    }

    .subheader__title h1 {
        font-size: 28px;
    }
}