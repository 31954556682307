/* WIDE SCREEN */
@media (min-width: 1200px) and (max-width: 1279px) {
	.col-lg-3 {
		width: 50%;
	}
	
	.list.list--letter .group__label {
		padding-left: 150px;
	}
	
	/* PEOPLE FIXES */
	.list .item__header div.item--title, .list .list__header div.item--title {
		width: 210px;
	}
	
	.list .item__header div.item--birthdate, .list .list__header div.item--birthdate,
	.list .item__header div.item--placeofbirth, .list .list__header div.item--placeofbirth,
	.list .item__header div.item--departure, .list .list__header div.item--departure,
	.list .item__header div.item--placeofdeath, .list .list__header div.item--placeofdeath {
		width: 150px;
	}
	
	.list .item__header div.item--placeofbirth, .list .list__header div.item--placeofbirth,
	.list .item__header div.item--placeofdeath, .list .list__header div.item--placeofdeath {
		width: 170px;		
	}
}

@media (min-width: 1280px) {
	.container {
		width: 1280px;
	}
	
	.list.list--letter .group__label {
		padding-left: 190px;
	}
	
	.list .list__header.header--letter div.item--title, .list .item--letter .item__header div.item--title {
	    width: 654px;
	}
	
	.header {
		.home {
			width: 100px;
			
		}
		
		.search {
			left: 121px;
		    width: 859px;
		}
	}
	
	.subheader {
		height: 150px;
		
		.date {
			&__wrapper {
				padding-top: 52px;
				font-size: 30px;
				line-height: 45px;
			}
			
			&__left {
				width: 60px;
				padding-top: 6px;
			}
			
			&__right {
				width: 170px;
			}
			
			&__day {
				font-size: 45px;
				line-height: 45px;
			}
			
			&__month {
				font-size: 24px;
				line-height: 28px;
				margin-top: 0px;
				display: block;
			}
			
			&__year {
				font-size: 84px;
				line-height: 84px;
			}
		}
		
		&__title {
			height: 150px;
			
			h1 {
				font-size: 30px;
				line-height: 45px;
			}
		}
	}
	
	.results {
	
		&__content {
			&:nth-child(3) {
				clear: none;
			}
		}
	
	}
	
	.list .item__header div.item--title, .list .list__header div.item--title {
		width: 405px;
	}
	
	.list .item__header div.item--placeofbirth, .list .list__header div.item--placeofbirth,
	.list .item__header div.item--placeofdeath, .list .list__header div.item--placeofdeath {
		width: 190px;		
	}

}

@media (min-width: 1280px) and (max-width: 1599px) {
	.results {
		&__item {
		    min-height: 134px;
			max-height: 134px;
			
			.excerpt {
			    max-height: 72px;
			}
		}
	}	
	
	.menu {
		.nav {
			&__item {
				width: 130px;
				height: 80px;
				border-bottom: none !important;
				background-position: center top 18px !important;
			}
			
			&__link {
				height: 80px;
				width: 130px;
				padding-top: 35px;
				text-align: center;
				padding-left: 0px;
			}
			
			&__list {
				max-width: none;
			}
		}
		
		.search {
		    width: 720px;
		}
	}
}

/* EXTRA WIDE SCREEN */
@media (min-width: 1600px) {
	
	.list .list__header.header--letter div.item--ep, .list .list__header.header--letter div.item--ms, .list .list__header.header--letter div.item--op, .list .list__header.header--letter div.item--ns, .list .list__header.header--letter div.item--no, .list .item--letter .item__header div.item--ep, .list .item--letter .item__header div.item--ms, .list .item--letter .item__header div.item--op, .list .item--letter .item__header div.item--ns, .list .item--letter .item__header div.item--no {
		width: 134px;
	}
	
	.list .list__header.header--letter div.item--title, .list .item--letter .item__header div.item--title {
	    width: 656px;
	}
	
	.list .item__header div.item--title, .list .list__header div.item--title {
		width: 690px;
	}
	
	.container {
		width: 1600px;
	}
	
	.list.list--letter .group__label {
		padding-left: 238px;
	}
	
	.show-xl {
		display: inline-block;
	}
	
	.header {
		.search {
			width: 940px;
		}
	}
	
	.menu {
		.search {
			width: 430px;
			margin-top: 20px;
			margin-left: 30px;
		    width: 400px;
		}
		
		.nav {
			margin-top: 20px;
		}
		
		&__buttons {
		    width: 610px;
		    float: left;
		    
		    a {
			    height: 80px;
			    line-height: 80px;

				&.btn-timeline-experience {
					font-size: 21px;
				}
		    }
		}
	}
	
	.nav {
		&__item {
			width: 130px;
			height: 80px;
			border-bottom: none !important;
			background-position: center top 18px !important;
		}
		
		&__link {
			height: 80px;
			width: 130px;
			padding-top: 35px;
			text-align: center;
			padding-left: 0px;
		}
		
		&__list {
			max-width: none;
		}
	}
}