@media (max-width: 991px) and (min-width: 768px) {
	
	.header {
		.search {
		    width: 345px
		}
	}
	
	.subheader {

		.date {
		    width: 200px;
		}

		&__title {
			padding-left: 20px;
			padding-right: 20px;
			width: 530px;
			
			h1 {
				font-size: 20px;
				line-height: 28px;
				padding: 0px;
			}
		}
	}	
	
	.menu {
		.search {
			width: 430px;
		}
	}
	
	.post {
		dl {
			margin-bottom: 0px;
			
			&:last-child {
				margin-bottom: 20px;
			}
		}
		
		/*dt {width:35% !important;}
		dd {width:65% !important;}*/
		
		&__entry {
			padding-bottom: 10px;
		}
	}
	
	.results {
		&__item {
		    min-height: 70px;
			max-height: 70px;
			
			.excerpt {
				max-height: 24px;
			    min-height: 24px;
			}
			
			.title {
				min-height: 34px;
				max-height: 34px;
			}
		}
		
		.results--places {
			.title--city, .title--country {
				display: inline-block;
			}
		}
		
		.results--people {
			.title--name, .title--date {
				display: inline-block;
			}
		}
	}
	
	/* LIST FIXES */
	.list {
		width: auto;
	}
	
	/* LETTER FIXES */
	.list .list__header.header--letter div.item--title, .list .item--letter .item__header div.item--title {
	    width: 240px;
	}
	
	.list .list__header.header--letter div.item--ep, .list .list__header.header--letter div.item--ms, .list .list__header.header--letter div.item--op, .list .list__header.header--letter div.item--ns, .list .list__header.header--letter div.item--no, .list .item--letter .item__header div.item--ep, .list .item--letter .item__header div.item--ms, .list .item--letter .item__header div.item--op, .list .item--letter .item__header div.item--ns, .list .item--letter .item__header div.item--no {
		width: 70px;
	}
	
	/* LOCATION FIXES */
	.list .list__header.header--place div.item--name, .list .item--place .item__header div.item--name {
		width: 33.3332%;
	}
	
	.list .list__header.header--place div.item--city, .list .item--place .item__header div.item--city,
	.list .list__header.header--place div.item--country, .list .item--place .item__header div.item--country,
	.list .list__header.header--place div.item--address, .list .item--place .item__header div.item--address {
		width: 33.3332%;
	}
	
	/* PEOPLE FIXES */
	.list .item__header div.item--title, .list .list__header div.item--title {
		width: 50%;
	}
	
	.list .item__header div.item--birthdate, .list .list__header div.item--birthdate,
	.list .item__header div.item--placeofbirth, .list .list__header div.item--placeofbirth,
	.list .item__header div.item--departure, .list .list__header div.item--departure,
	.list .item__header div.item--placeofdeath, .list .list__header div.item--placeofdeath {
		width: 25%;
	}
	
	/* WORK FIXES */
	.list .list__header.header--work div.item--publisher, .list .item--work .item__header div.item--publisher,
	.list .list__header.header--work div.item--translator, .list .item--work .item__header div.item--translator {
		width: 185px;
	}
	
}