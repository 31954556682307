$container-large-desktop: 1280;
$modal-backdrop-opacity: .9;

/* BOOTSTRAP */
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import url("/bower_components/mediaelement/build/mediaelementplayer.css");

/* FONTS */
@import "fonts";

/* ICONS */
@import "icons";

/* ANIMATIONS */
@import "animations";

/* SLIDER */
@import "slider";

input, textarea, select, a, span,
*:focus,
button,
a {
  outline: 0 !important;
}

.clearer {
  clear: both;
}

p {
  margin: 0 0 20px;
}

h1, h2, h3, h4, h5
.h1, .h2, .h3, .h4, .h5 {
  margin: 0;
  padding: 0;
}

.container {
  padding: 0 20px;
}

hr {
  border-top: 1px solid #cccccc;
  margin-top: 30px;
  margin-bottom: 30px;
}

::-moz-selection {
  color: #333333;
  background: #ece8cf;
}

::selection {
  color: #333333;
  background: #ece8cf;
}

::-webkit-input-placeholder {
  color: #333333;
}

:-moz-placeholder {
  color: #333333;
}

::-moz-placeholder {
  color: #333333;
}

:-ms-input-placeholder {
  color: #333333;
}

::-webkit-scrollbar-thumb {
  background: #a00057;
}

::-webkit-scrollbar {
  width: 10px;
  cursor: pointer;
  background: #e7e7e7;
}

/* CONFIG */
@import "config";

/* DESKTOP AND BASE */
@import "responsive/desktop";

/* WIDE SCREEN */
@import "responsive/wide";

/* TABLET PORTRAIT */
@import "responsive/tablet-portrait";

/* TABLET LANDSCAPE */
@import "responsive/tablet-landscape";

/* MOBILE SCREEN LANDSCAPE */
@import "responsive/mobile-landscape";

/* MOBILE SCREEN PORTRAIT */
@import "responsive/mobile-portrait";

/* PRINTING STYLES */
@import "print";

.modal.and.carousel {
  position: absolute;
}