@media (max-width: 767px) {
    .header {
        height: 60px;

        .home a {
            height: 60px;
            line-height: 60px;
        }
    }

    .media__slider {
        .media__wrapper {
            img {
                max-height: 220px;
            }
        }
    }

    .subheader {
        height: auto;
        padding-top: 25px;
        padding-bottom: 25px;

        .date {
            width: 100%;
            text-align: center;

            &__wrapper {
                padding-top: 10px;
            }
        }

        .close-btn {
            top: -15px;
            right: 10px;
            margin-top: 0px;
        }

        &__title {
            text-align: center;
            padding-left: 20px;
            padding-right: 20px;
            /*width: 100%;*/

            h1 {
                font-size: 26px;
                line-height: 26px;
                padding: 0px;
            }
        }
    }

    .menu {
        padding-bottom: 150px;
        height: 100vh !important;

        .top-nav {
            left: 0px;
            right: 0px;
            width: 100%;
            display: block;
            top: 0px;
            background: #8fb5b2;
            height: 60px;

            .home-btn {
                margin: 0px;
                left: 20px;
                top: 16px;
                float: none;
                position: absolute;
            }

            .close-btn {
                margin: 0px;
                right: 20px;
                top: 16px;
                float: none;
                position: absolute;
            }
        }

        .search {
            width: 100%;
            height: 50px;
            line-height: 50px;
            margin-top: 0px;
            margin-bottom: 20px;

            input, .bootstrap-tagsinput {
                line-height: 50px;
                height: 50px;
                padding-left: 10px;
                padding-right: 75px;
            }
        }

        .nav {
            width: 100%;
            background: transparent;

            &__list {
                max-width: none;
                box-sizing: border-box;
                width: 100%;
            }

            &__item {
                width: 50%;
                padding-left: 0px;
                padding-right: 7px;
                margin-bottom: 15px;
                border: none;
                background: none;

                &:nth-child(2),
                &:nth-child(4) {
                    padding-right: 0px;
                    padding-left: 7px;
                }

                a {
                    background-color: #8fb5b2;
                    border: 1px solid #82a4a0;
                    display: block;
                    float: left;
                    width: 100%;

                    &:hover, &:focus, &:active {
                        background-color: #82a4a0;
                        color: #ffffff;
                        text-decoration: none;
                    }
                }

                &.nav--works a {
                    background-image: url(frontend/img/icon-book-white-bg.svg);
                    background-repeat: no-repeat;
                    background-position: center left 13px;
                    background-size: 17px 22px;
                }

                &.nav--letters a {
                    background-image: url(frontend/img/icon-pencil-white-bg.svg);
                    background-repeat: no-repeat;
                    background-position: center left 13px;
                    background-size: 14px 23px;
                }

                &.nav--people a {
                    background-image: url(frontend/img/icon-person-white-bg.svg);
                    background-repeat: no-repeat;
                    background-position: center left 14px;
                    background-size: 14px 22px;
                }

                &.nav--places a {
                    background-image: url(frontend/img/icon-pointer-white-bg.svg);
                    background-repeat: no-repeat;
                    background-position: center left 11px;
                    background-size: 18px 22px;
                }
            }
        }

        &__nav {
            width: 100%;
            top: 380px;
            position: absolute;
            display: block;
            left: 20px;
            right: 20px;

            .menu__item {
                width: 25%;
            }
        }

        &__item {

        }

        &__buttons {
            margin-top: 80px;
            margin-bottom: 20px;

            a {
                height: 50px;
                line-height: 50px;
                margin-bottom: 20px;
                font-size: 20px;
            }

            .col-sm-6:last-child a {
                margin-bottom: 0px;
            }
        }
    }

    .media__slider {
        margin-bottom: 25px;
    }

    .post {
        dl {
            margin-bottom: 0px;

            &:last-child {
                margin-bottom: 20px;
            }
        }

        &__entry {
            padding-bottom: 10px;
        }
    }

    .filters__item {
        width: 50%;
        margin-bottom: 10px;
        margin-left: 0px;
    }

    /* LIST FIXES */

    /* LETTER FIXES */
    .list.list--letter .group__label {
        padding-left: 30%;
        margin-left: 15px;
    }

    .list .list__header.header--letter div.item--title, .list .item--letter .item__header div.item--title {
        width: 45%;
    }

    .list .list__header.header--letter div.item--date, .list .item--letter .item__header div.item--date {
        width: 30%;
        min-width: inherit;
    }

    .list .list__header.header--letter div.item--ep, .list .list__header.header--letter div.item--ms, .list .list__header.header--letter div.item--op, .list .list__header.header--letter div.item--ns, .list .list__header.header--letter div.item--no, .list .item--letter .item__header div.item--ep, .list .item--letter .item__header div.item--ms, .list .item--letter .item__header div.item--op, .list .item--letter .item__header div.item--ns, .list .item--letter .item__header div.item--no {
        float: right;
        width: 25%;
    }

    /* LOCATION FIXES */
    .list .list__header.header--place div.item--name, .list .item--place .item__header div.item--name,
    .list .list__header.header--place div.item--city, .list .item--place .item__header div.item--city,
    .list .list__header.header--place div.item--country, .list .item--place .item__header div.item--country,
    .list .list__header.header--place div.item--address, .list .item--place .item__header div.item--address {
        width: 50%;
    }

    /* LIST FIXES */
    .list {
        width: auto;
    }

    /* PEOPLE FIXES */
    .list .item__header div.item--title, .list .list__header div.item--title {
        width: 100%;
    }

    /* WORK FIXES */
    .list .list__header.header--work div.item--date, .list .item--work .item__header div.item--date,
    .list .list__header.header--work div.item--title, .list .item--work .item__header div.item--title {
        width: 50%;
    }
}