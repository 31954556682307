/* BASICS */
html {
	height: 100%;
	width: 100%;
	padding: 0;
	margin: 0;
	overflow-x: hidden;
}

body {
	background: $white;
	padding: 0;
	margin: 0;
	width: 100%;
	overflow-x: hidden;
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.row.row--nomargin {
	margin: 0;
}

a {
	/*border-bottom: 1px solid #a00057;*/
    color: #a00057;
    font-weight: bold;
    text-decoration: none;
    
    &:hover, &:focus, &:active {
	    color: #333333;
	    /*border-color: #333333;*/
    }
}

/* MAIN HEADER */
.header {
	height: 80px;
	width: 100%;
	background: $light_green;
	margin: 0;
	padding: 0;
	
	.container {
		position: relative;
	}
	
	.home {
		width: 85px;
		left: 20px;
		position: absolute;
		top: 0;
		
		a {
			height: 80px;
			width: 30px;
			margin-right: 10px;
			display: block;
			background: transparent;
			float: left;
			text-indent: -99999px;
			overflow: hidden;
			line-height: 80px;
		}
		
		.home-btn {
			background: url(frontend/img/icon-home-bg.svg) no-repeat center center;
			background-size: 28px 24px;
		}
		
		.menu-btn {
			background: url(frontend/img/icon-menu-bg.svg) no-repeat center center;
			background-size: 22px 22px;
		}
	}
}

.nav {
	position: absolute;
	right: 20px;
	top: 0;
	
	&__list {
		margin: 0;
		padding: 0;
		list-style-type: none;
		max-width: 280px;
		float: right;
		background: transparent;
	}
	
	&__item {
		margin: 0;
		padding: 0;
		list-style-type: none;
		float: left;
		width: 140px;
		height: 40px;
		border-right: 1px solid #82a4a0;
		transition:all 0.5s;
		-webkit-transition:all 0.5s;
		-o-transition:all 0.5s;
		-moz-transition:all 0.5s;
					
		&:hover, &:focus {
			background-color: #82a4a0;
			
			a {
				text-decoration: none;
				color: #ffffff;
			}
		}
	}
	
	&__item:nth-child(-n+2) {
		border-bottom: 1px solid #82a4a0;
	}
	
	&__item.nav--works {
		background-image: url(frontend/img/icon-book-white-bg.svg);
		background-repeat: no-repeat;
		background-position: center left 13px;
		background-size: 17px 22px;
	}
	
	&__item.nav--letters {
		background-image: url(frontend/img/icon-pencil-white-bg.svg);
		background-repeat: no-repeat;
		background-position: center left 13px;
		background-size: 14px 23px;
	}
	
	&__item.nav--gallery {
		background-image: url(frontend/img/icon-media-white-bg.svg);
		background-repeat: no-repeat;
		background-position: center left 9px;
		background-size: 23px 18px;
	}
	
	&__item.nav--people {
		background-image: url(frontend/img/icon-person-white-bg.svg);
		background-repeat: no-repeat;
		background-position: center left 14px;
		background-size: 14px 22px;
	}
	
	&__item.nav--places {
		background-image: url(frontend/img/icon-pointer-white-bg.svg);
		background-repeat: no-repeat;
		background-position: center left 11px;
		background-size: 18px 22px;
	}
	
	&__item.nav--ideas {
		background-image: url(frontend/img/icon-lamp-white-bg.svg);
		background-repeat: no-repeat;
		background-position: center left 14px;
		background-size: 15px 22px;
	}
	
	&__link {
		float: left;
		color: #ffffff;
		display: block;
		width: 140px;
		height: 40px;
		background: transparent;
		padding-left: 42px;
		line-height: 40px;
		font-size: 13px;
		text-transform: uppercase;
		text-decoration: none;
		transition:all 0.5s;
		-webkit-transition:all 0.5s;
		-o-transition:all 0.5s;
		-moz-transition:all 0.5s;
	}
}

/* MENU */
body.menu--active .menu {
	display: block;
}

.menu {
	display: none;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	width: 100%;
	background: #0e1002;
	z-index: 999;
	
	.nav {
		position: relative;
		background: #8fb5b2;
		left: auto;
		right: auto;
		top: auto;
		display: inline-block;
		float: left;
		margin-bottom: 30px;
	}
	
	.search {
		position: relative;
		left: auto;
		top: auto;
		float: left;
		display: inline-block;
		margin-bottom: 30px;
		width: 650px;
	}
	
	.top-nav {
		position: absolute;
		right: 18px;
		top: 25px;
		
		a {
			height: 28px;
			width: 30px;
			margin-left: 10px;
			display: block;
			background: transparent;
			float: left;
			text-indent: -99999px;
			overflow: hidden;
			line-height: 28px;
			
			&:hover, &:focus {
				opacity: 0.75;
			}
		}
		
		.home-btn {
			background: url(frontend/img/icon-home-bg.svg) no-repeat center center;
			background-size: 28px 24px;
		}
		
		.close-btn {
			background: url(frontend/img/menu-close-btn-bg.svg) no-repeat center center;
			background-size: 24px 24px;
		}
	}
	
	.container {
		position: relative;
		display: block;
	}
	
	&__list,
	&__item {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}
	
	&__list {
		padding-top: 30px;
		padding-bottom: 30px;
		display: block;
		width: auto;
		
		&.list--right {
			position: absolute;
			right: 0;
			top: 0;
		}
	}
	
	&__item {
		float: left;
		margin-right: 30px;
		
		a {
			color: #8f958b;
			font-size: 14px;
			text-decoration: none;
			transition:all 0.5s;
			-webkit-transition:all 0.5s;
			-o-transition:all 0.5s;
			-moz-transition:all 0.5s;
			
			&:hover, &:focus {
				color: #a9b2a4;
			}
		}
	}
	
	&__buttons {
		margin-top: 20px;
		margin-bottom: 30px;
		width: 100%;
		
		a {
			display: block;
			float: left;
			height: 75px;
			width: 100%;
			padding-left: 65px;
			color: #ffffff;
			font-size: 25px;
			line-height: 75px;
			font-weight: normal;
			font-family: 'Roboto Slab', 'Helvetica', 'Arial', sans-serif;
			text-decoration: none;
			cursor: pointer;
			background: url(frontend/img/icon-arrow-down.svg) no-repeat left 16px center;
			background-size: 30px 26px;
			transition:all 0.5s;
			-webkit-transition:all 0.5s;
			-o-transition:all 0.5s;
			-moz-transition:all 0.5s;
		
			&.btn-timeline-experience {
				background-color: #9a9543;
				
				&:hover, &:focus {
					background-color: #7c7836;
				}
			}
			
			&.btn-database-search {
				background-color: #8fb5b2;

				&:hover, &:focus {
					background-color: #82a4a0;
				}
			}
		}
	}
}

/* TABLE */
.list {
	width: auto;
	margin-left: -15px;
	margin-right: -15px;
	
	&__header {
		display: flex;
		flex-flow: row wrap;
		color: #b2b894;
		font-size: 15px;
		padding-bottom: 10px;
		text-transform: uppercase;
		margin-bottom: 10px;
		line-height: 15px;
		width: 100%;
		/*border-bottom: 1px solid #d2d1d1;*/
		font-family: 'Roboto Condensed', 'Helvetica', 'Arial', sans-serif;
		font-weight: 700;
		
		a {
			color: #b2b894;
			text-decoration: none;
			width: 100%;
			display: block;
		}
				
		.current--sorting a {
			color: #a00057;
			position: relative;
			
			&:after {
				margin-left: 5px;
				width: 15px;
				height: 15px;
				border-radius: 15px;
				background: url(frontend/img/icon-arrow-bottom.png);
				background-size: 15px 15px;
				display: inline-block;
				content: " ";
				margin-bottom: -2px;
			}
		}
		
		.current--sorting.sort--asc a {
			&:after {
				background: url(frontend/img/icon-arrow-up.png);
				background-size: 15px 15px;
			}
		}
		
		div {
			padding-bottom: 20px;
			padding-left: 15px;
			font-size: 14px;
			width: auto;
		}
	}
	
	.group {
		margin-bottom: 45px;
		/*border-bottom: 1px solid #d2d1d1;*/
		
		&__label {
			font-weight: normal;
			font-size: 17px;
            width: 100%;
			display: block;
            padding: 15px 15px 5px;
            font-family: 'Roboto Condensed', 'Helvetica', 'Arial', sans-serif;
		}
	}
	
	&.list--letter .group {
		&__label {
			padding-left: 150px;
			margin-left: 15px;
		}
	}
	
	.item__header, .list__header {
		div {
			padding: 5px 15px;
			width: auto;
			font-size: 14px;
			
			&.item--gender {
				width: 11%;
				min-width: 110px;
			}
			
			&.item--title {
				width: 255px;
			}
			
			&.item--birthdate {
				width: 165px;
			}
			
			&.item--placeofbirth {
				width: 180px;
			}
			
			&.item--departure {
				width: 180px;
			}
			
			&.item--placeofdeath {
				width: 180px;
			}
		}
	}
	
	.list__header.header--letter,
	.item--letter .item__header {
			
		div {
			&.item--date {
				width: 15%;
				min-width: 150px;
			}
			
			&.item--title {
				width: 380px;
			}
			
			&.item--ep, &.item--ms, &.item--op, &.item--ns, &.item--no {
				width: 80px;
				text-align: center;
			}
			
			&.item--ms {
				position: relative;
				span {
					font-size: 30px;
					font-weight: bold;
					position: absolute;
					left: 0;
					right: 0;
					margin: 0 auto;
					width: auto;
					display: inline-block;
					top: 11px;
					line-height: 10px;
				}
			}
		}
		
	}
	
	.list__header.header--place,
	.item--place .item__header {
			
		div {
			&.item--name {
				width: 25%;
			}
			
			&.item--address {
				width: 25%;
			}
			
			&.item--city {
				width: 25%;
			}
			
			&.item--country {
				width: 25%;
			}
		}
		
	}
	
	.list__header.header--work,
	.item--work .item__header {
			
		div {
			&.item--title {
				width: 11%;
				min-width: 0;
			}
			
			&.item--collation {
				width: 29%;
				min-width: 0;
			}
			
			&.item--variant {
				width: 15%;
				min-width: 0;
			}
			
			&.item--date {
				width: 15%;
				min-width: 0;
			}
			
			&.item--publisher {
				width: 15%;
				min-width: 0;
			}
			
			&.item--translator {
				width: 15%;
				min-width: 0;
			}
		}
		
	}
	
	.item {
	    font-size: 14px;
	    width: 100%;
	    
	    &:first-child {
		    .item__header {
			    border-top: 1px solid #d2d1d1;
		    }
	    }
	    
	    &__media {
		    img {
			    width: 100px;
			    float: right;
			    margin-left: 15px;
			    max-width: 100%;
		    }
	    }
		
		&__header {
			display: flex;
			flex-flow: row wrap;
			cursor: pointer;
			color: #ffffff;
			background-color: #050203;
			width: 100%;
			border-bottom: 1px solid #d2d1d1;
			font-family: 'Roboto Condensed', 'Helvetica', 'Arial', sans-serif;
			font-weight: 500;
			font-size: 15px;
			
			&.collapsed {
				background-color: #ffffff;
				color: #000000;
				
				&:hover, &:active {
					color: #ffffff;
					background-color: #050203;
				}
			}
		}
		
		&__content {
			flex: 4;
			
			.entry {
				width: 100%;
				font-size: 15px;
				line-height: 24px;
				padding: 15px;
				padding-top: 20px;
				padding-bottom: 20px;
				background: #ffffff;
			}

			&.in {
				border-bottom: 1px solid #d2d1d1;
			}
		}
	}
}
.more-btn {
	color: #a00057;
	position: relative;
	display: block;
	margin-top: 15px;

	&:after {
		margin-left: 10px;
		width: 15px;
		height: 15px;
		border-radius: 15px;
		background: url(frontend/img/icon-arrow-right.png);
		background-size: 15px 15px;
		display: inline-block;
		content: " ";
		margin-bottom: -2px;
	}
}

.media {
	.more-btn {
		display: inline-block;
		border-bottom: 0 none;
		margin-top: 0;

		&:after {
			margin-left: 0;
		}
	}
}

/* SEARCH */
.bootstrap-tagsinput {
	background-color: transparent;
	border: none;
	box-shadow: none;
	display: inline-block;
	vertical-align: middle;
	max-width: 100%;
	cursor: text;
	position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
   	padding-left: 25px;
   	padding-right: 100px;
    line-height: 80px;
    height: 80px;
    max-height: 80px;
    overflow: hidden;
}

.bootstrap-tagsinput input {
	border: none;
	box-shadow: none;
	outline: none;
	background-color: transparent;
	padding: 0;
	margin: 0;
	width:auto;
}

.bootstrap-tagsinput.form-control input::-moz-placeholder {
	color: #777;
	opacity: 1;
}

.bootstrap-tagsinput.form-control input:-ms-input-placeholder {
	color: #777;
}

.bootstrap-tagsinput.form-control input::-webkit-input-placeholder {
	color: #777;
}

.bootstrap-tagsinput input:focus {
	border: none;
	box-shadow: none;
}

.bootstrap-tagsinput .tag {
	margin-right: 2px;
	color: #ffffff;
	padding-left: 5px;
	padding-right: 5px;
	background: #a00057;
}

.bootstrap-tagsinput .tag [data-role="remove"] {
	margin-left: 8px;
	cursor: pointer;
}
.bootstrap-tagsinput .tag [data-role="remove"]:after {
	content: "x";
	padding: 0 2px;
}

.bootstrap-tagsinput .tag [data-role="remove"]:hover {
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
}

.bootstrap-tagsinput .tag [data-role="remove"]:hover:active {
	box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.search {
	top: 0;
	height: 80px;
	width: 565px;
	display: block;
	position: absolute;
	left: 105px;
	background: #ffffff;
	background-size: 28px 28px;
	border-left: 1px solid #82a4a0;
	border-right: 1px solid #82a4a0;
	color: #8f958b;
	line-height: 80px;
	font-size: 21px;
	font-family: 'Roboto Slab', 'Helvetica', 'Arial', sans-serif;
	padding-left: 28px;
	overflow: hidden;
	
	input {
		border: none;
		text-shadow: none;
	}

	&__input {
		width: 835px;
	}
	
	&__btn {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		height: 100%;
		background: url(frontend/img/icon-search-bg.svg) no-repeat center right 23px;
		background-size: 28px 28px;
		border: none;
		cursor: pointer;
		width: 74px;
		text-indent: -99999px;
		overflow: hidden;
	}
}

/* SUBHEADER */
.subheader {
	height: 100px;
	width: 100%;
	background: $light_yellow;
	padding: 0;
	margin-bottom: 40px;
	
	.container {
		position: relative;
		/*padding:0px;*/
	}
	
	.close-btn {
		position: absolute;
		right: 20px;
		top: 50%;
		margin-top: -12px;
		line-height: 24px;
		height: 24px;
		width: 24px;
		background: url(frontend/img/close-btn-bg.svg) no-repeat center center;
		background-size: 24px 24px;
		text-align: center;
		display: block;
		text-indent: -99999px;
		overflow: hidden;
	}
	
	&__date {
		height: 100%;
		display: table;
	}
	
	.date {
		font-family: 'Roboto Slab', 'Helvetica', 'Arial', sans-serif;
		font-weight: 100;
		text-align: right;
		color: #8fb5b2;
		/*width: 235px;*/
		/*margin-right: 20px;*/
		float: left;
		display: block;
		height: inherit;
		
		&__wrapper {
			margin: 0 auto;
			width: auto;
			display: inline-block;
		    font-size: 22px;
		    line-height: 32px;
		    padding-top: 34px;
		}
		
		&__left {
			width: 50px;
			float: left;
			padding-top: 6px;
		}
		
		&__right {
			width: 115px;
			float: left;
		}
		
		&__day {
			font-size: 30px;
			line-height: 30px;
		}
		
		&__month {
			font-size: 16px;
			line-height: 16px;
			text-transform: uppercase;
		}
		
		&__year {
			font-size: 56px;
			line-height: 56px;
		}
	}
	
	&__title {
		position: relative;
		display: table;
		height: 100px;
		/*width: 650px;*/
		text-align: left;
		float: left;
		
		h1 {
			font-family: 'Roboto Slab', 'Helvetica', 'Arial', sans-serif;
			font-size: 22px;
			line-height: 32px;
			color: #0e1002;
			display: table-cell;
			vertical-align: middle;
			padding-right: 100px;
			font-weight: 400;
			
			span {
				color: #8fb5b2;
				font-weight: 300;
				
				&.search-label {
					margin-right: 10px;
				}
			}
			
			span.term {
				color: #0e1002;
				font-weight: 400;
			}
		}
	}
}

/* FILTERS */
.filters {
	width: 100%;
	display: block;
	
	&__label {
		font-size: 16px;
		color: #0e1002;
		float: left;
		display: inline-block;
		line-height: 22px;
		padding-top: 5px;
		padding-bottom: 5px;
	}
	
	&__list,
	&__item {
		padding: 0;
		margin: 0;
		list-style-type: none;
	}
	
	&__list {
		display: inline-block;
	}
	
	&__item {
		float: left;
		margin-left: 15px;
		
		a {
			font-size: 13px;
			text-transform: uppercase;
			height: auto;
			line-height: 22px;
			position: relative;
			display: block;
			padding: 5px;
			padding-left: 30px;
			transition:all 0.5s;
			-webkit-transition:all 0.5s;
			-o-transition:all 0.5s;
			-moz-transition:all 0.5s;

			&.turned-off {
				background-color: #CCCCCC !important;
				cursor: default !important;
				color: #FFFFFF !important;
			}
		}
	}
	
	&__works {
		background-image: url(frontend/img/icon-book-color.svg);
		background-repeat: no-repeat;
		background-position: center left 5px;
		background-size: 17px 22px;
		color: #8fb5b2;
		text-decoration: none;
		
		&:hover, &:focus, &.current--filter, &.turned-off {
			background-image: url(frontend/img/icon-book-white-bg.svg);
			background-repeat: no-repeat;
			background-position: center left 5px;
			background-size: 17px 22px;
			background-color: #8fb5b2;
			color: #ffffff;
			text-decoration: none;
		}
	}
	
	&__letters {
		background-image: url(frontend/img/icon-pencil-color.svg);
		background-repeat: no-repeat;
		background-position: center left 5px;
		background-size: 14px 23px;
		color: #a27c56;
		padding-left: 27px !important;
		
		&:hover, &:focus, &.current--filter, &.turned-off {
			background-image: url(frontend/img/icon-pencil-white-bg.svg);
			background-repeat: no-repeat;
			background-position: center left 5px;
			background-size: 14px 23px;
			background-color: #a27c56;
			color: #ffffff;
			text-decoration: none;
		}
	}
	
	&__people {
		background-image: url(frontend/img/icon-person-color.svg);
		background-repeat: no-repeat;
		background-position: center left 5px;
		background-size: 14px 22px;
		color: #a98e30;
		padding-left: 25px !important;
		
		&:hover, &:focus, &.current--filter, &.turned-off {
			background-image: url(frontend/img/icon-person-white-bg.svg);
			background-repeat: no-repeat;
			background-position: center left 5px;
			background-size: 14px 22px;
			background-color: #a98e30;
			color: #ffffff;
			text-decoration: none;
		}
	}
	
	&__places {
		background-image: url(frontend/img/icon-pointer-color.svg);
		background-repeat: no-repeat;
		background-position: center left 5px;
		background-size: 14px 22px;
		color: #6f7744;
		padding-left: 25px !important;
		
		&:hover, &:focus, &.current--filter, &.turned-off {
			background-image: url(frontend/img/icon-pointer-white-bg.svg);
			background-repeat: no-repeat;
			background-position: center left 5px;
			background-size: 14px 22px;
			background-color: #6f7744;
			color: #ffffff;
			text-decoration: none;
		}
	}
}

.show-xl {
	display: none;
}

.results {
	padding-bottom: 100px;
	
	&__loading {
		text-align: center;
		border-top: 1px solid #d2d1d1;
		margin-top: 40px;
		margin-bottom: 40px;
		padding-top: 60px;
		font-size: 16px;
		color: #a00057;
		background: url(frontend/img/loader.gif) no-repeat center top 20px;
	}
	
	&__content {
		&:nth-child(3) {
			clear: both;
		}
	}
	
	&__header {
		border-top: none;
		margin-top: 20px;
		height: auto;
		overflow: hidden;
		position: relative;
		display: block;
		width: 100%;
		padding-top: 15px;
		padding-bottom: 15px;
		margin-bottom: -5px;
		
		.title {
			font-size: 30px;
			color: #93b7b5;
			line-height: 30px;
			margin: 0;
			padding: 0;
			font-weight: bold;
			text-transform: uppercase;
		    /*font-family: 'Roboto Slab', 'Helvetica', 'Arial', sans-serif;*/
		}
		
		.show-all {
			position: absolute;
			right: 0;
			bottom: 13px;
			display: block;
			cursor: pointer;
			color: #a00057;
			font-size: 16px;
			font-weight: normal;
			font-family: 'Roboto Condensed', 'Helvetica', 'Arial', sans-serif;
		}
	}
	
	&__more {
		margin-top: 25px;
		margin-bottom: 25px;
		border-top: 1px solid #d2d1d1;
		padding-top: 0;
		height: 30px;
		position: relative;
		font-family: 'Roboto Condensed', 'Helvetica', 'Arial', sans-serif;

		.show-all {
			position: absolute;
			right: 0;
			top: 15px;
			display: block;
			cursor: pointer;
			color: #a00057;
			font-size: 16px;
			font-weight: normal;
		}
	}
	
	&__link {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		display: block;
		cursor: pointer;
		text-indent: -99999px;
		overflow: hidden;
		background: transparent;
		z-index: 1;
	}
	
	&__none {
		border-top: 1px solid #d2d1d1;
		margin-top: 25px;
		line-height: 30px;
		min-height: 120px;
		max-height: 120px;
		overflow: hidden;
		position: relative;
		display: block;
		width: 100%;
		transition:all 0.5s;
		-webkit-transition:all 0.5s;
		-o-transition:all 0.5s;
		-moz-transition:all 0.5s;
	    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
	    font-weight: bold;
		font-size: 18px;
		color: #93b7b5;
		font-weight: bold;
	}
	
	&__item {
		border-top: 1px solid #d2d1d1;
		margin-top: 25px;
		line-height: 24px;
		min-height: 110px;
		max-height: 110px;
		overflow: hidden;
		position: relative;
		display: block;
		width: 100%;
		transition:all 0.5s;
		-webkit-transition:all 0.5s;
		-o-transition:all 0.5s;
		-moz-transition:all 0.5s;
	   // font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
	    font-family: 'Roboto Condensed', 'Helvetica', 'Arial', sans-serif;
		
		&:hover {
			.title {
				color: #000000;
			}
			
			.excerpt {
				color: #000000;
			}
			
			.highlight, mark {
				color: #000000;
			}
		}
		
		.highlight, mark {
			color: #93b7b5;
			padding-left: 3px;
			padding-right: 3px;
			padding-bottom: 3px;
			background: transparent !important;
			font-weight: bold;
		}
		
		.title {
			font-weight: bold;
			min-height: 55px;
			max-height: 55px;
			overflow: hidden;
			position: relative;
			font-size: 18px;
			color: #93b7b5;
			font-weight: bold;
			line-height: 24px;
			padding-top: 5px;
			padding-bottom: 5px;
			border-bottom: 1px solid #d2d1d1;
			margin-bottom: 5px;
		}
		
		.excerpt {
			overflow: hidden;
			position: relative; 
			line-height: 24px;
			max-height: 48px;
			text-align: left;  
			padding-right: 1em;
			width: 100%;
			color: #0e1002;
			font-size: 18px;
					
			/*&:before {
				content: '...';
				position: absolute;
				right: 0;
				bottom: 0;
			}
			
			&:after {
				content: '';
				position: absolute;
				right: 0;
				width: 1em;
				height: 1em;
				margin-top: 0.2em;
				background: white;
			}*/
		}
	}
	
	.results--letters {
		.results__header .title,
		.results__item .title,
		.results__none {
			color: #a27c56;
		}
		
		.results__item .highlight, .results__item mark {
			color: #a27c56;
		}
		
		.results__item:hover {
			.title,
			.excerpt {
				color: #000000;
			}
			
			.highlight, mark {
				color: #000000;
			}
		}
	}
	
	.results--places {
		.results__header .title,
		.results__item .title,
		.results__none {
			color: #6f7744;
		}
		
		.results__item .title .title--country {
			font-weight: normal;
		}
		
		.results__item .highlight, .results__item mark {
			color: #6f7744;
		}
		
		.results__item:hover {
			.title,
			.excerpt {
				color: #000000;
			}
			
			.highlight, mark {
				color: #000000;
			}
		}
	}
	
	.results--people {
		.results__header .title,
		.results__item .title,
		.results__none {
			color: #a98e30;
		}
		
		.results__item .title .title--date {
			font-weight: normal;
		}
		
		.results__item .highlight, .results__item mark {
			color: #a98e30;
		}
		
		.results__item:hover {
			.title,
			.excerpt {
				color: #000000;
			}
			
			.highlight, mark {
				color: #000000;
			}
		}
	}

}

/* POST */
.post {
	
	ul, ol {
		padding-left: 17px;
	}
	
	&__list {
		
		dl {
			font-size: 15px;
			line-height: 21px;
		}
		
		dt, .post__label {
			text-transform: uppercase;	
			font-size: 15px;
			color: #b2b894;
			font-weight: 400;
			float: left;
			width: 25%;
			display: inline-block;
			clear: both;
			margin-bottom: 5px;
		}
		
		dd, .post__value {
			text-transform: uppercase;	
			font-size: 15px;
			color: #262626;
			font-weight: 400;
			float: left;
			width: 75%;
			display: inline-block;
			margin-bottom: 5px;
		}
	}
	
	&__entry {
		font-size: 15px;
		line-height: 21px;
		font-weight: 400;
		color: #0e1002;
		padding-bottom: 40px;
		
		h1, h2, h3, h4, h5 {
			font-weight: bold;
			font-size: 15px;
			line-height: 21px;
		}
	}
	
	a {
		color: #a00057;
		font-weight: bold;
		text-decoration: none;
		border-bottom: 1px solid #a00057;
		
		&:hover, &:focus {
			color: #640037;
		}
	}
}

/* ASIDE MEDIA */
.media {
	&__item {
		margin-bottom: 45px;
		width: 100%;
		display: block;
	}
	
	&__figure {
		padding: 0;
		margin: 0;
		width: 100%;
		display: inline-block;
		/*margin-bottom: 10px;*/
		
		a {
			text-align: center;
			width: 100%;
			display: block;
			float: left;
			border: 1px solid #e3b5cf;
			margin-bottom: 10px;
		}
	}
	
	&__img, img {
		width: auto;
		display: block;
		max-width: 100%;
		margin: 0 auto;
		max-height: 220px;
		/*border: 1px solid #e3b5cf;*/
	}
	
	&__caption {
		text-transform: uppercase;
		font-size: 12px;
		color: #0e1002;
		line-height: 16px;
		
		a {
			color: #a00057;
			text-decoration: none;
			border-bottom: 1px solid #a00057;
			
			&:hover, &:focus {
				
			}
		}
	}
}

/* ASIDE BLOCK */
.block {
	border-bottom: 1px solid #8f958b;
	margin-bottom: 20px;
	padding-bottom: 20px;

	&.no-border {
		border-bottom: 0 none;
	}

	&.just-border {
		margin-top: -40px;
	}

	&.block--download {
		a {
			background: #a00056;
			color: #ffffff;
			margin-top: 5px;
			text-decoration: none;
			display: inline-block;
			padding: 4px 10px;
			margin-right: 7px;
			
			&:hover, &:focus {
				background: #272624;
			}
			
			&:last-child {
				margin-right: 0;
			}
		}
	}
	
	&:first-child {
		border-top: 1px solid #8f958b;
		padding-top: 20px;
	}
	
	h4, &__title {
		text-transform: uppercase;
		font-size: 15px;
		line-height: 21px;
		color: $pink;
	}

	&.relationships h4 {
		margin-bottom: 15px;
	}
}

.post__entry {
	.block {
		border-bottom: 0;
	}
}

/* ACCORDION */
.accordion {
	padding-top: 10px;
	margin-bottom: 0;
	
	h5 {
		text-transform: uppercase;
		font-size: 15px;
		line-height: 21px;
		color: #0e1002;
		
		a {
			text-decoration: none;
			width: 100%;
			display: block;
			
			&:hover, &:focus {
				color: #0e1002 !important;
			}
		}
		
		a.collapsed {
			color: $pink;
		}
	}
	
	.panel-default>.panel-heading {
		background: transparent;
		padding: 0;
		border: none;
		border-radius: 0;
	}
	
	&.panel-group .panel {
		border: none;
		border-radius: 0;
		-webkit-box-shadow: none;
		box-shadow: none;
	}
	
	&.panel-group .panel-heading+.panel-collapse>.list-group, 
	&.panel-group .panel-heading+.panel-collapse>.panel-body {
		border-top: none;
	}
	
	.panel-body {
		padding: 0;
		font-family: 'Roboto Condensed', 'Helvetica', 'Arial', sans-serif;
	}
}

/* RELATED */
.related {
	float: none;
	width: 100%;
    list-style-type: none;
    padding: 0;
    display: inline-block;
	clear: both;
    margin: 0 0 -15px;
    /** Set to 210px if all 6 icons are present **/
    max-width: 150px;
	
	&__item {
		margin: 0;
		padding: 0;
		list-style-type: none;	
		float: left;
		display: block;
		margin-top: 10px;
		margin-bottom: 0;
	}
	
	&__link {
		float: left;
		width: 70px;
		display: block;
		height: 55px;
		cursor: pointer;
		background-position: center center;
		background-repeat: no-repeat;
		position: relative;
		transition:all 0.5s ;
		-webkit-transition:all 0.5s ;
		-o-transition:all 0.5s ;
		-moz-transition:all 0.5s ;
		
		.count {
			position: absolute;
			left: 10px;
			top: 0;
			border-radius: 17px;
			height: 17px;
			width: 17px;
			text-align: center;
			line-height: 17px;
			color: #ffffff;
			background: $pink;
			font-size: 10px;	
		}
		
		&:hover, &:focus {
			-webkit-transform: scale(1.2);
			-moz-transform: scale(1.2);
			-ms-transform: scale(1.2);
			-o-transform: scale(1.2);
			transform: scale(1.2); 
		}
	}
	
	&__item.related--book .related__link {
		background-image: url(frontend/img/icon-book-bg.svg);
	}
	
	&__item.related--pencil .related__link {
		background-image: url(frontend/img/icon-pencil-bg.svg);
	}
	
	&__item.related--media .related__link {
		background-image: url(frontend/img/icon-media-bg.svg);
	}
	
	&__item.related--person .related__link {
		background-image: url(frontend/img/icon-person-bg.svg);
	}
	
	&__item.related--pointer .related__link {
		background-image: url(frontend/img/icon-pointer-bg.svg);
	}
	
	&__item.related--lamp .related__link {
		background-image: url(frontend/img/icon-lamp-bg.svg);
	}
}

/* SLIDER */
.media__slider {
	margin-bottom: 45px;
	padding-top: 10px;
	
	.media__wrapper {
		background: #8f958b;
		padding-top: 10px;
		padding-bottom: 10px;
		margin-bottom: 10px;
		
		img {
			max-height: 350px;
		}
	}
	
	.slick-arrow {
		position: absolute;
		top: 0;
		right: 0;
		width: 25px;
		display: block;
		height: 25px;
		border-radius: 25px;
		background: transparent;
		border: none;
		text-indent: -9999px;
		overflow: hidden;
		z-index: 999;
		background-size: 25px 25px;
		background-repeat: no-repeat;
		background-position: center center;
		
		&.slick-prev {
			right: 35px;
			background-image: url(frontend/img/icon-arrow-left.png);
		}
		
		&.slick-next {
			background-image: url(frontend/img/icon-arrow-right.png);
		}
	}
	
	.slider__arrows {
		position: absolute;
		right: 0;
		top: 0;
	}
	
	.slider__text {
		text-transform: uppercase;
		padding-right: 70px;
		position: relative;
		min-height: 25px;
        max-height: 4.5em;
        overflow: hidden;
		
		a {
			text-transform: uppercase;
			color: #b11e69;
			text-decoration: none;
			border-bottom: 1px solid #b11e69;
		}
	}
}

/* RELATIONSHIPS */
.relationships {
	.col-sm-6:nth-child(odd) {
		clear: both;
	}
}

/* CONVERSATION */
.conversation {
	padding: 0;
	margin: 0;
	
	ul {
		padding: 0;
		margin: 0;
		padding-left: 0;
	}
	
	li {
		background: url(frontend/img/icon-list-bg.svg) no-repeat left top 5px;
		padding: 0;
		margin: 0;
		padding-left: 20px;
		list-style-type: none;
		line-height: 25px;
		font-size: 14px;
		font-family: 'Roboto Condensed', 'Helvetica', 'Arial', sans-serif;
	}
	
	&>li {
		list-style-type: none;
		background: none;
		padding-left: 0 !important;
		font-family: 'Roboto Condensed', 'Helvetica', 'Arial', sans-serif;
	}
	
	li span {
		list-style-type: none;
		font-weight: bold;
		padding: 0;
		margin: 0;
	}
}

/* BOX */
.box {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100vh !important;
	z-index: 1000;
	display: block;
	overflow-x: hidden;
	overflow-y: auto;
}
	
.box__wrapper {
	margin: 0 auto;
	max-width: 90%;
	width: auto !important;
	max-height: 90%;
	top: 50px;
	position: relative;
	-webkit-overflow-scrolling: touch;
}
	
.box__entry {
	background: transparent;
	color: #ffffff;
	padding: 0;
	-webkit-overflow-scrolling: touch;
	text-transform: uppercase;
	
	a {
		color: #ffffff;
		text-decoration: none;
		border-bottom: 1px solid #ffffff;
		font-size: 14px;
	}
	
	img {
		max-height: 90vh;
		max-width: 90vh;
		margin: 0 auto;
		margin-bottom: 10px;
	}
}

.box__entry div {
	width: 100%;
}
	
.box__entry input[type="text"],
.box__entry input[type="file"],
.box__entry textarea {
	margin: 0;
	border: none;
	background: #e6e6e6;
	padding: 15px;
	font-weight: normal;
	display: block;
	width: 100%;
	margin-bottom: 10px;
	font-size: 16px;
	line-height: 21px;
	border-radius: 0;
	box-shadow: none;
	color: #000000;
	max-width: 100%;
}

.box__entry textarea {
	/*background: #e6e6e6 url(../assets/_chat/textarea-bg.jpg);
	background-repeat: repeat;
	background-attachment: scroll;
    background-position: center;
	line-height: 30px;
	padding: 0;*/
}

.box__entry .btn-round {
	background: #b70d5e;
	width: 120px;
	height: 120px;
	border-radius: 120px;
	border: none;
	color: #ffffff;
	text-transform: uppercase;
	font-size: 16px;
	text-align: center;
	font-weight: bold;
	display: block;
	cursor: pointer;
}

.box__entry .btn-round:hover {
	background: #dc901b;
}

.box__entry .form {
	margin-bottom: 50px;
}

.inspiration__title {
    position: relative;
}
	
.box__title,
.inspiration__title .title {
	font-size: 28px;
	line-height: 34px;
	margin-bottom: 25px;
	font-weight: bold;
	color: #0079c2;
	display: block;
	width: 100%;
	text-transform: uppercase;
}

.inspiration__title .date {
	position: absolute;
	right: 0;
	bottom: 0;
	color: #b70d5e;
}

.inspiration__title .title,
.box__title .title {
	margin-bottom: 5px;
}

.inspiration__title .author,
.box__title .author {
	font-size: 18px;
	color: #b70d5e;
	display: block;
	width: 100%;
	margin-bottom: 15px;
	font-weight: normal;
	text-transform: none;
}
	
.box p {
	font-size: 18px;
	line-height: 28px;
	font-weight: normal;
	color: #000000;
}
	
.box__bottom {
	width: 100%;
	display: inline-block;
	padding-top: 10px;
}
	
.box__bottom .btn {
	float: right;
	margin-right: 27px;
}	
	
.box__close {
	position: absolute;
	right: 10px;
	top: 10px;
	height: 50px;
	width: 50px;
	border-radius: 50px;
	display: inline-block;
	background: transparent;
	color: #ffffff;
	line-height: 50px;
	text-align: center;
	font-size: 20px;
	font-weight: bold;
	padding: 0;
	margin: 0;
	cursor: pointer;
	z-index: 9999;
}

.box__close:hover {
	background: transparent;
}

.btn-add-comment {
	line-height: 120px;
	position: absolute;
	right: -60px;
	top: 300px;
	margin-top: -60px;
	opacity: 1;
}

.btn-add-comment:hover {
	opacity: 1;
}

.box--voorwaarden p {
	font-weight: normal !important;
}

.box__response {
	margin-bottom: 20px;
}			
		
.box__response	.error {
	color: #8a6d3b;
	background-color: #fcf8e3;
	font-weight: bold;
	padding: 15px;
    border: 1px solid #faebcc;
	margin-bottom: 10px;
}

.box__response .success {
	color: #3c763d;
    background-color: #dff0d8;
    border: 1px solid #d6e9c6;
    padding: 15px;
}

.box__inner {
	background: transparent;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
	max-height: 100vh;
	display: block;
	z-index: 1;
}
	
.box__overlay {
	background: rgba(11, 11, 11, 0.95);
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
	display: none;
	z-index: 999;
	overflow: hidden;
}

body.box--active .box__overlay {
	display: block;
}

body.box--active .slider-wrapper,
body.box--active .btn-add-inspiration,
body.box--active nav.side {
	display: none;
}

body.box--active {
	overflow: hidden;
	position: relative;
	z-index: 1;
	width: 100%;
	max-width: 100%;
	height: 100vh;
	max-height: 100%;
	-webkit-overflow-scrolling:touch;
}

.box--view-inspiration .row {
	display: inline-block;
	clear: both;
}

.box--view-inspiration .left {
	width: 60%;
	float: left;
	font-size: 18px;
	line-height: 28px;
	display: inline-block;
}

.box--view-inspiration .right {
	width: 40%;
	float: right;
	display: inline-block;
	padding-left: 50px;
	padding-right: 50px;
}

.media__slider {
	.slider__arrows {
		top: 257px;
		right: 15px;
	}

	.carousel-inner {
		padding-bottom: 50px;
	}

	.item {
		background-color: #8f958b;
		padding-top: 10px;
		padding-bottom: 10px;

		.carousel-caption {
			text-transform: uppercase;
			text-shadow: none;
			color: #333333;
			text-align: left;

			top: 104%;
			padding: 0;
			left: 0;
			right: 70px;
			max-height: 4.5em;
		}
	}
}

#lightbox {
	text-align: center;

	.modal-dialog {
		display: inline-block;
		width: auto !important;
	}

	.modal-body {
		padding: 5px;
	}

	.modal-header {
		padding: 0 5px;
		border: 0 none;

		.close {
			color: #FFFFFF;
			opacity: 1;

			background-image: url(frontend/img/menu-close-btn-bg.svg);
			background-size: 24px 24px;

			height: 24px;
			width: 24px;
			margin-bottom: 10px;

			&:hover {
				opacity: 1;
			}
		}
	}

	.modal-content {
		border-radius: 0;
		border: 0 none;
		background-color: transparent;
	}

	.carousel-inner {
		.carousel-caption {
			position: absolute;
			color: #FFFFFF;
			top: auto;
			bottom: -5px;
			height: 0;
		}

		.item {
			background-color: transparent;
			padding: 0;
			margin: 0;

			img {
				height: 80vh;
			}
		}
	}

	.carousel-indicators {
		bottom: 70px;

		li {
			border: 1px solid #333333;

			&.active {
				background-color: #333333;
			}
		}
	}

	&.media__slider {
		padding-top: 0;
		margin-bottom: 0;
	}

	.slider__arrows {
		bottom: 51px;
		right: 5px;
		top: auto;
	}
}